.calculator {
  text-align: center;
}

.im-logo {
  width: 10em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
  background-color: #FFC16A !important;
}

.ddown {
  background-color: #FFC16A !important;
  color: #141414 !important;
}

.ddown:hover {
  background-color: #997440 !important;
}

.ddown-menu {
  background-color: #FFC16A !important;
  border-color: #141414 !important;
}